import React from "react"

export const AngelList = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 21.9 31.4"
  >
    <g>
      <path
        d="M18.3,13.4c1.3,0.2,2.2,0.8,2.7,1.6c0.5,0.8,0.8,2.2,0.8,4c0,3.6-1.1,6.6-3.3,8.9c-2.2,2.3-5,3.5-8.4,3.5
		c-1.3,0-2.6-0.2-3.9-0.7c-1.3-0.5-2.3-1.2-3.2-2c-1-0.9-1.8-2-2.3-3C0.3,24.6,0,23.5,0,22.4c0-1.2,0.3-2.2,0.8-2.9
		c0.5-0.7,1.4-1.1,2.5-1.4c-0.2-0.5-0.4-0.9-0.5-1.3c-0.1-0.3-0.2-0.6-0.2-0.8c0-0.6,0.3-1.3,1-2c0.7-0.7,1.3-1,1.9-1
		c0.3,0,0.5,0,0.8,0.1c0.3,0.1,0.6,0.2,1,0.5C6.4,10.6,5.5,8.1,5,6.4C4.5,4.7,4.3,3.6,4.3,2.9C4.3,2,4.5,1.3,5,0.8
		C5.4,0.3,6.1,0,6.9,0C8.2,0,9.9,3,12,9.1c0.4,1,0.6,1.8,0.8,2.4c0.2-0.4,0.4-1.1,0.7-1.9c2.1-6,3.9-9,5.3-9c0.7,0,1.3,0.2,1.8,0.7
		c0.4,0.5,0.7,1.2,0.7,2c0,0.6-0.2,1.8-0.7,3.5C20,8.5,19.3,10.7,18.3,13.4z M2.9,22.2c0.2,0.2,0.5,0.6,0.8,1.1c0.9,1.3,1.8,2,2.6,2
		c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.5c0-0.2-0.1-0.6-0.4-1.1c-0.3-0.5-0.7-1.1-1.2-1.7c-0.6-0.7-1.1-1.3-1.4-1.6
		c-0.4-0.3-0.7-0.5-0.9-0.5c-0.5,0-1,0.3-1.4,0.8c-0.4,0.5-0.6,1.2-0.6,1.9c0,0.6,0.1,1.2,0.4,2c0.3,0.7,0.7,1.5,1.3,2.2
		C4,27.5,5,28.4,6.3,29c1.3,0.6,2.6,0.9,4.2,0.9c2.8,0,5.1-1,7-3.1c1.9-2.1,2.8-4.7,2.8-7.9c0-1-0.1-1.7-0.2-2.3
		c-0.1-0.6-0.4-1-0.7-1.2c-0.6-0.5-1.7-0.9-3.4-1.3c-1.7-0.4-3.5-0.6-5.3-0.6c-0.5,0-0.9,0.1-1.1,0.3c-0.2,0.2-0.3,0.5-0.3,0.9
		c0,1,0.5,1.7,1.6,2.1c1.1,0.4,2.9,0.7,5.3,0.7h0.9c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.2,0.4,0.2,0.7c-0.2,0.2-0.7,0.5-1.5,0.8
		c-0.8,0.3-1.3,0.6-1.7,0.9c-0.9,0.6-1.6,1.4-2.1,2.3c-0.5,0.9-0.8,1.7-0.8,2.5c0,0.5,0.1,1,0.3,1.7c0.2,0.7,0.3,1.1,0.3,1.2v0.2
		l0,0.2c-0.6,0-1.1-0.4-1.5-1.1c-0.4-0.7-0.5-1.6-0.5-2.8v-0.2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.3,0-0.4,0
		c-0.1,0-0.2-0.1-0.4-0.1c0,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.3,0,0.4c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.5,0.6
		c-0.9,0-1.8-0.4-2.8-1.3c-0.9-0.9-1.4-1.7-1.4-2.6c0-0.2,0-0.3,0.1-0.4C2.7,22.4,2.8,22.3,2.9,22.2z M9.4,22.7
		c0.2,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.2-0.9-0.6-1.9c-0.4-1-0.9-2-1.5-2.9c-0.4-0.7-0.9-1.3-1.3-1.6
		c-0.4-0.4-0.8-0.5-1.2-0.5c-0.3,0-0.6,0.2-1,0.6c-0.4,0.4-0.5,0.7-0.5,1.1c0,0.3,0.2,0.9,0.5,1.6c0.4,0.7,0.8,1.4,1.4,2.2
		c0.6,0.8,1.2,1.5,1.8,2C8.6,22.5,9,22.7,9.4,22.7z M11.5,12.3L9.1,5.6C8.5,3.9,8.1,2.7,7.7,2.2C7.4,1.7,7.1,1.4,6.7,1.4
		c-0.3,0-0.5,0.1-0.7,0.3C5.7,2,5.6,2.3,5.6,2.7c0,0.7,0.3,1.8,0.8,3.4c0.5,1.6,1.3,3.8,2.3,6.4c0.1-0.2,0.2-0.3,0.4-0.3
		c0.2-0.1,0.4-0.1,0.6-0.1c0.1,0,0.3,0,0.5,0C10.5,12.2,10.9,12.2,11.5,12.3z M13.9,18.8c-0.6,0-1.2-0.1-1.8-0.2
		c-0.6-0.1-1.1-0.2-1.6-0.4c0.2,0.5,0.4,0.9,0.6,1.4c0.2,0.5,0.3,0.9,0.4,1.4c0.3-0.4,0.7-0.8,1.1-1.2
		C13.1,19.4,13.5,19.1,13.9,18.8z M16.8,13c1-2.6,1.7-4.8,2.3-6.5c0.5-1.7,0.8-2.7,0.8-3.1c0-0.4-0.1-0.7-0.3-1
		c-0.2-0.2-0.4-0.3-0.7-0.3c-0.4,0-0.8,0.3-1.2,1c-0.4,0.7-0.9,1.7-1.4,3.2l-2.2,6.2L16.8,13z"
      />
    </g>
  </svg>
)
